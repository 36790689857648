export type SliderTypes = "tv" | "net" | "radio" | "print" | "direct"

//labeliai gali persimaisyti/pasikeisti kad atspindeti siu dienu realybe
export const sliders = [
    {name: "tv", label: "Televizija"},//miestieciams
    {name: "net", label: "Internetas"},//jaunimui
    {name: "radio", label: "Radijas"},//regionams
    {name: "print", label: "Spauda"},//senjorams
    {name: "direct", label: "Tiesioginis kontaktas"}
] as const

export const questionCategories: { category: string, key: "economics" | "culture" | "internalPolicy" | "foreignPolicy" }[] = [
    {category: "Ekonomika", key: "economics"},
    {category: "Kultūra", key: "culture"},
    {category: "Vidaus politika", key: "internalPolicy"},
    {category: "Užsienio politika", key: "foreignPolicy"}
]

export type StepState = {
    choice: null | 0 | 1 | 2,
    tv: number,
    net: number,
    radio: number,
    print: number,
    direct: number
}

export const initialMoveState: StepState[] = [
    {
        choice: null,
        tv: 0,
        net: 0,
        radio: 0,
        print: 0,
        direct: 0,
    },
    {
        choice: null,
        tv: 0,
        net: 0,
        radio: 0,
        print: 0,
        direct: 0,
    },
    {
        choice: null,
        tv: 0,
        net: 0,
        radio: 0,
        print: 0,
        direct: 0,
    },
    {
        choice: null,
        tv: 0,
        net: 0,
        radio: 0,
        print: 0,
        direct: 0,
    },
]