import {Route, Switch} from 'wouter'
import Home from "../pages/home";
import NotFound from "../pages/404";
import GameRouter from "./GameRouter";

export default function PageRouter() {

    return (
        <Switch>
            <Route path={"/game/:code"}>
                {({code}: {code: string}) => <GameRouter code={code} />}
            </Route>
            <Route path="/">
                <Home/>
            </Route>
            <Route>
                <NotFound/>
            </Route>
        </Switch>
    )
}