import Title from "antd/lib/typography/Title";
import {Button, Divider, Form, Input, message} from "antd";
import {GoogleOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {useAuth, useFirestore} from "reactfire";
import {sendSignInLink, signInWithGoogle} from "../../firebase/auth-api";
import {createUserDocument} from "../../firebase/firestore-api";
import firebase from 'firebase';

const debugging = true;
export default function LoginLayout() {

    const [loading, setLoading] = useState({email: false, google: false});
    const auth = useAuth();
    const firestore = useFirestore();
    const [form] = Form.useForm();
    const googleLogin = async () => {
        setLoading(prev => ({...prev, google: true}));

        try{
            const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
            debugging && console.log("retrieved provider")
            const credential = await auth.signInWithPopup(GoogleAuthProvider);
            debugging && console.log("retrieved credential")
            if(credential.additionalUserInfo?.isNewUser){
                await createUserDocument(firestore, credential.user!!.uid).then(() => {
                    setLoading(prev => ({...prev, google: false}));
                });
                debugging && console.log("Created account")
            }
        }catch(e){
            console.error(e); message.error("Nenumatyta klaida naudojant Google prisijungimą.")
        }
        /*
        setLoading(prev => ({...prev, google: false}));
        const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
        debugging && console.log("retrieved provider")
        const credential = await auth.signInWithPopup(GoogleAuthProvider);
        debugging && console.log("retrieved credential")
        if(credential.additionalUserInfo?.isNewUser){
            createUserDocument(firestore, credential.user!!.uid).then(() => {
                setLoading(prev => ({...prev, google: false}));
            });
        }
        signInWithGoogle(auth)
            .then((credential) => {
                if (credential.additionalUserInfo?.isNewUser) {
                    createUserDocument(firestore, credential.user!!.uid).then(() => {
                        setLoading(prev => ({...prev, google: false}));
                    });
                }
            })
            .catch(reason => {console.error(reason); message.error("Nenumatyta klaida naudojant Google prisijungimą.")})
            .finally(() => setLoading(prev => ({...prev, google: false})))
            */
    }
    const emailLogin = async () => {
        message.error('Šiuo metu šis prisijungimo būdas neveikia, prašome naudoti Google prisijungimą.');
        // TODO: email login disabled for now, because it's buggy
        /*try {
            setLoading(prev => ({...prev, email: true}));
            await form.validateFields();
            const email = form.getFieldValue("email") as string
            try {
                await sendSignInLink(auth, email);
                // Save email to make process simpler later.
                window.localStorage.setItem('emailForSignIn', email);
                setLoading(prev => ({...prev, email: false}));
                message.success('Prisijungimas išsiųstas į nurodytą el. paštą!');
            }
            catch (e) {
                console.error(e);
            }
        }
        catch (e) {
            console.warn(e);
        } finally {
            setLoading(prev => ({...prev, email: false}));
        }*/
    }

    return (
        <div>
            <Title level={1}>Prisijunkite</Title>
            <p>
                Kiekvieną kartą jungdamiesi jūs gausite nuorodą į nurodytą el. paštą, kurią paspaudę būsite
                prijungti - nereikia jokio slaptažodžio!
                <br/>
                Taip pat galite prisijungti su savo Google paskyra.
            </p>
            <Form form={form} requiredMark={false} layout="vertical" style={{maxWidth: "500px", margin: "0 auto"}}
                  validateTrigger={false}>
                <Form.Item name="email" rules={[{required: true, message: 'Įveskite el. paštą'},
                    {type: "email", message: "Neteisingas el. pašto formatas."}]}>
                    <Input placeholder="email@email.com" style={{textAlign: "center"}}/>
                </Form.Item>
                <Form.Item>
                    <Button onClick={emailLogin} loading={loading.email}>Prisijungti</Button>
                </Form.Item>
            </Form>
            <Divider style={{marginTop: "2.75em"}}>Kiti prisijungimo būdai</Divider>
            <Button onClick={googleLogin} loading={loading.google}>
                <GoogleOutlined/>
                Prisijungti su Google
            </Button>
        </div>
    )
}
