import {preloadAuth, preloadFirestore} from "reactfire";
import firebase from "firebase/app";
import {PlayerDoc, SocialGroup} from "./firestoreTypes";


/**
 * Initialize firebase with emulators in dev environment
 * */
export const initializeFirebase = async (firebaseConfig: Object) => {

    const firebaseApp = firebase.initializeApp(firebaseConfig);

    await preloadAuth({
        firebaseApp,
        setup: async (factory) => {
            const auth = factory();
            if (process.env.NODE_ENV !== "production") {
                ///auth.useEmulator("http://78.61.221.10:9099"); // nice dox?
                auth.useEmulator("http://127.0.0.1:9099");
            }
            await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
            return auth;
        }
    });

    await preloadFirestore({
        firebaseApp,
        setup: async (factory) => {
            const firestore = factory();
            if (process.env.NODE_ENV !== "production") {
                //firestore.useEmulator("78.61.221.10", 8080); // nice dox?
                firestore.useEmulator("127.0.0.1", 8080); 
            }
            return firestore;
        }
    });
    return firebaseApp;
}

export const getRandomNumberString = (length: number) => {
    if (length === 0) {
        return "";
    }
    const min = Math.pow(10, length - 1);
    const max = Math.pow(10, length);
    return Math.floor(Math.random() * (max - min) + min).toString(); //The maximum is exclusive and the minimum is inclusive
}
/**
 * Returns random sample of given size from given array.
 * From {@link https://stackoverflow.com/a/11935263/5215314}
 * */
export const getRandomSubarray = <T>(arr: T[], size: number): T[] => {
    let shuffled = arr.slice(0), i = arr.length, temp, index;
    while (i--) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(0, size);
}

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];

type Key<T> = keyof T;

/**
 * Makes the Object.entries() return with typed keys.
 * Use only with constant objects
 * */
export const ObjectEntries = <T extends object>(t: T): Entries<T>[] => {
    return Object.entries(t) as any;
}

/**
 * Makes the Object.keys() return with typed keys.
 * Use only with constant objects
 * */
export const ObjectKeys = <T extends object>(t: T): Key<T>[] => {
    return Object.keys(t) as any;
}


/**
 * Returns fraction as percentage string with specified precision and postfix
 * */
export const toPercentage = (n: number, precision = 2, postfix = '%') => (
    `${(n * 100).toFixed(precision)} ${postfix}`
)

/**
 * Sorts the copy of array of players according to the key in they scores and returns it.
 * */
export const sortPlayers = (players: PlayerDoc[], key: SocialGroup | "totals" = "totals", byChange: boolean = false) => (
    [...players].sort((a, b) => (
        b.score[key][byChange ? "change" : "support"] - a.score[key][byChange ? "change" : "support"]
    ))
)

/**
 * Sorts the copy of array of players according to the money and returns it.
 * */
export const sortPlayersByMoney = (players: PlayerDoc[]) => (
    [...players].sort((a, b) => (
        b.money - a.money
    ))
)

/**
 * Returns the player's place in the give players array
 * */
export const getPlace = (players: PlayerDoc[], player: PlayerDoc) => (
    sortPlayers(players).findIndex(val => val.userId === player.userId) + 1
)

export const maxGroup = (player: PlayerDoc) => {
    const dict = {
        jaunimas: "Jaunimas",
        miestieciai: "Miestiečiai",
        regionai: "Regionai",
        senjorai: "Senjorai"
    } as const
    let maxLabel = "Jaunimas"
    let maxVal = 0
    // TODO: Fix TS errors
    for (const [group, label] of Object.entries(dict)) {
        //@ts-ignore
        const newVal = player.score[group].support
        if (newVal > maxVal) {
            maxVal = newVal;
            //@ts-ignore
            maxLabel = dict[group];
        }
    }
    return maxLabel;
}

export const getColor = (hexBgColor: string) => {
    if (hexBgColor) {
        const [r, g, b] = [0, 2, 4].map(p => parseInt(hexBgColor.substr(p, 2), 16));
        return ((r * 299) + (g * 587) + (b * 114)) / 1000 >= 128 ? "black" : "white";
    }
    return "inherit"
}

export const newsHeadings = [
    {
        string: '• Partija <b>„%p#totals%“</b> per šį ėjimą pasiekė geriausių bendrų rezultatų ir pritraukė daugiausiai rinkėjų.',
        divider: false,
    },
    {
        string: '• Partija <b>„%m#money%“</b> šiuo metu turi sukaupusi didžiausius finansinius išteklius.',
        divider: false,
    },
    {
        string: '• Jaunimas labiausiai palaiko partiją <b>„%p#jaunimas%“</b>.',
        divider: true,
    },
    {
        string: '• Miesto gyventojai labiausiai remia partiją <b>„%p#miestieciai%“</b>.',
        divider: false,
    },
    {
        string: '• Partija <b>„%p#regionai%“</b> turi didžiausią regionų gyventojų palankumą.',
        divider: false,
    },
    {
        string: '• Partija <b>„%p#senjorai%“</b> šiuo metu gali pasigirti didžiausiu senjorų palaikymu.',
        divider: false,
    },
    {
        string: '• Partija <b>„%c#jaunimas%“</b> po paskutiniojo ėjimo sulaukė daugiausiai papildomų balsų iš jaunimo.',
        divider: true,
    },
    {
        string: '• Partija <b>„%c#miestieciai%“</b> po paskutiniojo ėjimo sulaukė daugiausiai miesto gyventojų palankumo.',
        divider: false,
    },
    {
        string: '• Po paskutinio ėjimo daugiausiai balsų iš regionų gyventojų pritraukė partija <b>„%c#regionai%“</b>.',
        divider: false,
    },
    {
        string: '• Daugiausiai senyvo amžiaus žmonių paramos po paskutiniojo ėjimo sulaukė partija <b>„%c#senjorai%“</b>.',
        divider: false,
    },
    {
        string: '• Partija <b>„%-c#jaunimas%“</b> po praėjusio ėjimo neteko daugiausiai jaunimo grupės paramos.',
        divider: true,
    },
    {
        string: '• Partija <b>„%-c#miestieciai%“</b> po paskutinio ėjimo labiausiai nuvylė savo rinkėjus iš miesto.',
        divider: false,
    },
    {
        string: '• Daugiausiai regiono gyventojų po šio ėjimo nusigręžė nuo partijos <b>„%-c#regionai%“</b>.',
        divider: false,
    },
    {
        string: '• Senyvo amžiaus žmonės po paskutiniojo ėjimo labiausiai nusivylė partija <b>„%-c#senjorai%“</b>.',
        divider: false,
    }
] as const
