import {ObservableStatus, useFirestore, useFirestoreDocData, useUser} from "reactfire";
import Lobby from "../pages/lobby";
import Game from "../pages/game";
import Results from "../pages/results";
import NotFound from "../pages/404";
import {RouterDoc} from "../firebase/firestoreTypes";


export default function GameRouter({code}: {code: string}) {

    //TODO: Handle users who are not in the game visiting this page

    const gameRef = useFirestore().collection(code);
    const user = useUser();
    const gameRouter: ObservableStatus<RouterDoc> = useFirestoreDocData(gameRef.doc('router'));

    const renderPage = () => {
        switch (gameRouter.data.path){
            case "lobby": return <Lobby code={code}/>;
            case "game": return <Game code={code}/>;
            case "results": return <Results code={code}/>;
            default: return <NotFound/>;
        }
    }
    if(user.status === "success"){
        gameRef.where("uid", "==", user.data?.uid).get().then(r =>
            {
                if(r.empty) {
                    return <NotFound/>
                }
            }
        )
    }
    switch(gameRouter.status){
        case "success": return renderPage();
        case "loading": return null;
        case "error": {
            console.error(gameRouter.error);
            return null
        }
    }

}