import React from 'react';
import {AuthCheck} from 'reactfire';
import Login from "./pages/login";
import PageRouter from "./routers/PageRouter";

function App() {
    return (
        <AuthCheck fallback={<Login/>}>
            <PageRouter/>
        </AuthCheck>
    );
}

export default App;
