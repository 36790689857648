import {StepState} from "../components/QuestionLayout/Consts";

export const backendFinishMove = async (moveState: StepState[], gameCode: string, move: number, partyName: string) => {
    const body = {
        results: [...moveState],
        gameCode: gameCode,
        move: move,
        partyName: partyName
    }
    const resp = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/finishMove`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
    });
    if(resp.ok){
        // If everything is ok, return 0
        return 0
    }
    else {
        throw Error(resp.status.toString())
    }
}
export const backendInterruptMove = async (gameCode: string) => {
    const body = {
        gameCode: gameCode
    }
    console.log(process.env);
    const resp = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/interruptMove`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });
    if(resp.ok){
        // If everything is ok, return 0
        return 0
    }
    else {
        throw Error(resp.status.toString())
    }
}