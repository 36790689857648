import styles from './Home.module.css'
import {Layout} from 'antd';
import React from "react";
import {Route, Switch} from "wouter";
import LoginLayout from "../components/LoginLayout/LoginLayout";
import LoginConfirm from "../components/LoginLayout/LoginConfirm";
import NotFound from "./404";

const {Header, Content, Footer} = Layout;

export default function Login() {

    return (
        <Layout className={styles.layout}>
            <Header>
                <div className={styles.logo}>
                    Electo
                </div>
            </Header>
            <Content style={{padding: '50px'}}>
                <div className={styles.content}>
                    <Switch>
                        <Route path="/">
                            <LoginLayout/>
                        </Route>
                        <Route path="/login-confirm">
                            <LoginConfirm/>
                        </Route>
                        <Route>
                            <NotFound/>
                        </Route>
                    </Switch>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©2021 Artificia</Footer>
        </Layout>
    )
}
