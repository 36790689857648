import {useEffect, useState} from "react";
import styles from "./LobbyNamesList.module.css"
import randomColor from "randomcolor";
import {getColor} from "../../firebase/utils";

export default function LobbyNamesList({names, seed}: { names: string[], seed?: string | number }) {

    const [colors, setColors] = useState<string[]>([]);

    useEffect(() => {
        if (names.length > colors.length) {
            const newColors = randomColor({count: names.length - colors.length, seed: seed});
            setColors(prevState => [...prevState, ...newColors])
        }
    }, [names]);

    return (
        <div className={styles.wrapper}>
            {names.map((name, i) => <div style={{
                    backgroundColor: colors[i],
                    color: getColor(colors[i])
                }} className={styles.item}>
                    {name}
                </div>
            )}
        </div>
    )
}