import {PlayerDoc} from "../../firebase/firestoreTypes";
import styles from "./ResultsLayout.module.css";
import {Collapse, Divider} from "antd";
import {newsHeadings, sortPlayers, sortPlayersByMoney} from "../../firebase/utils";

export default function NewsHeadings({players}: {players: PlayerDoc[]}){

    const {Panel} = Collapse;
    const placeholderRegex = /%(-?[pcm])#(\w+)%/

    const replacingFunction = (match: string, p1: string, p2: string): string => {
        if (players.length > 0) {
            switch (p1) {
                case "p":
                    return sortPlayers(players, p2 as any)[0].partyName;
                case "c":
                    return sortPlayers(players, p2 as any, true)[0].partyName;
                case "-c":
                    return sortPlayers(players, p2 as any, true).pop()?.partyName ?? "???";
                case "m":
                    return sortPlayersByMoney(players)[0].partyName;
                default:
                    return "???";
            }
        }
        return "???"
    }

    return (
        <Collapse className={styles.collapse}>
            <Panel header="Antraštės" key="1">
                {newsHeadings.map((el, i) => (
                    <div key={i}>
                        <div
                            style={{margin: "1em 0"}}
                            dangerouslySetInnerHTML={{"__html": el.string.replace(placeholderRegex, replacingFunction)}}
                        >
                        </div>
                        {el.divider ? <Divider/> : null}
                    </div>
                ))}
            </Panel>
        </Collapse>
    )
}
