import styles from './Home.module.css'
import {Avatar, Button, Col, Layout, List, Row, Tooltip} from 'antd';
import {CrownOutlined, PlusSquareTwoTone, ProfileTwoTone, UserOutlined} from "@ant-design/icons";
import IconCardButton from "../components/IconCardButton";
import Title from "antd/lib/typography/Title";
import {useEffect, useState} from "react";
import {CreateNewGameForm, JoinGameForm} from "../components/ModalForms";
import {useLocation} from "wouter";
import {useAuth, useFirestore, useUser} from "reactfire";
import {createNewGame, getUserGames, joinGame} from "../firebase/firestore-api";
import {ParamsDoc} from "../firebase/firestoreTypes";

const {Header, Content, Footer} = Layout;

export default function Home() {

    const [visible, setVisible] = useState({createForm: false, joinForm: false});
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [gamesHistory, setGamesHistory] = useState<ParamsDoc[] | null>(null);
    const [location, setLocation] = useLocation();
    const user = useUser();
    const auth = useAuth();
    const firestore = useFirestore();

    const goToGame = (code: string) => setLocation(`game/${code}`);

    const onCreate = (values: { title: string, totalMoves: number, questionSetSelected: 1 | 2 },
                      setError: (field: string, errorMsg: string) => void) => {
        console.log('Received values of form: ', values);
        createNewGame(firestore, values.title, values.totalMoves, user.data.uid, values.questionSetSelected)
            .then((code) => {
                setVisible({createForm: false, joinForm: false});
                goToGame(code);
            })
            .catch(e => {
                console.error(e);
                // No specific errors for this request
                setError("title", "Įvyko klaida. Bandykite dar kartą.");
            });
    };
    const onJoin = (values: { code: string, partyName: string },
                    setError: (field: string, errorMsg: string) => void) => {
        console.log('Received values of form: ', values);

        joinGame(firestore, values.code, values.partyName, user.data.uid)
            .then(() =>{
                setVisible({createForm: false, joinForm: false});
                    goToGame(values.code);
            }

            )
            .catch(e => {
                // if e has field property, it's the validation error message.
                if(e.field){
                    console.warn("Validation Failed", e);
                    setError(e.field, e.msg)
                }
                else {
                    console.error(e);
                }
            });
    };

    useEffect(() => {
        if (user.status === "success") {
            getUserGames(firestore, user.data.uid).then((games: ParamsDoc[]) => setGamesHistory(games))
        }
    }, [])

    const isAdmin = (creatorUid: string) => {
        if (user.status === "success") {
            return user.data?.uid === creatorUid
        }
        return false
    }

    return (
        <Layout className={styles.layout}>
            <Header>
                <div className={styles.logo}>
                    Electo
                </div>
                <Row className={styles.account} gutter={16}>
                    <Col>
                        <Avatar size="large" icon={<UserOutlined/>}/>
                    </Col>
                    <Col>
                        <p>{user.data?.displayName}</p>
                    </Col>
                    <Col>
                        <Button onClick={async () => {
                            await auth.signOut();
                            window.location.reload();
                        }}>Atsijungti</Button>
                    </Col>
                </Row>
            </Header>
            <Content style={{padding: '50px'}}>
                <div className={styles.content}>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <IconCardButton icon={PlusSquareTwoTone} title="Naujas"
                                            description="Sukurti naują žaidimą"
                                            onClick={() => setVisible({...visible, createForm: true})}/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <IconCardButton icon={ProfileTwoTone} title="Prisijungti"
                                            description="Prisijungti prie sukurto žaidimo"
                                            onClick={() => setVisible({...visible, joinForm: true})}/>
                        </Col>
                    </Row>
                    <div>
                        <Title style={{marginTop: "1em"}} level={2}>Žaidimų istorija</Title>
                        <List
                            style={{textAlign: "left", cursor: "pointer", fontSize: "1.25em"}}
                            size={"large"}
                            itemLayout="horizontal"
                            dataSource={gamesHistory ?? []}
                            loading={gamesHistory === null}
                            locale={{emptyText: "Nėra išsaugotų žaidimų"}}
                            renderItem={item => (
                                <List.Item onClick={() => goToGame(item.code)}>
                                    <List.Item.Meta
                                        title={item.name}
                                        description={
                                            <span style={{display: "flex"}}>
                                                <p style={{margin: 0}}>{`Ėjimas ${item.move}`}</p>
                                                <Tooltip
                                                    title={isAdmin(item.createdBy) ?
                                                        "Jūs esate administratorius" : "Jūs esate žaidėjas"}
                                                    placement="right"
                                                >
                                                    {isAdmin(item.createdBy) ?
                                                        <CrownOutlined
                                                            style={{margin: "3px 5px 0 5px", color: "orange"}}/>
                                                        :
                                                        <UserOutlined
                                                            style={{margin: "3px 5px 0 5px"}}/>
                                                    }

                                                </Tooltip>
                                            </span>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </div>
                </div>
            </Content>
            <Footer style={{textAlign: 'center'}}>©2020 Artificia</Footer>
            <CreateNewGameForm visible={visible.createForm} onCreate={onCreate}
                               onCancel={() => setVisible({...visible, createForm: false})}/>
            <JoinGameForm visible={visible.joinForm} onCreate={onJoin}
                          onCancel={() => setVisible({...visible, joinForm: false})}/>
        </Layout>
    )
}
