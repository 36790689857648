import {List} from "antd";
import {CheckCircleTwoTone} from "@ant-design/icons";
import styles from "./PlayersList.module.css"
import {PlayerDoc} from "../../firebase/firestoreTypes";
import {sortPlayers, toPercentage} from "../../firebase/utils";

type PlayersListProps = {
    players: PlayerDoc[],
    showResults?: boolean,
    currentPlayer: string
}
export default function PlayersList({players, currentPlayer, showResults = false}: PlayersListProps) {

    return (
        <List
            style={{textAlign: "left"}}
            itemLayout="horizontal"
            dataSource={sortPlayers(players)}
            renderItem={item => (
                <List.Item className={styles.listitem}
                           style={{fontWeight: item.userId === currentPlayer ? 700 : "inherit"}}>
                    <CheckCircleTwoTone twoToneColor={"#52c41a"}
                                        style={{
                                            padding: "0 4px",
                                            fontSize: "1.2em",
                                            opacity: item.moved ? 1 : 0
                                        }}/>
                    {item.partyName}

                    {showResults ?
                        <span style={{float: "right"}}>
                            {toPercentage(item.score.totals.support)}
                        </span> : null
                    }
                </List.Item>
            )}
        />
    )
}