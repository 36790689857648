import {Button, Layout} from "antd";
import styles from "./GameHeader.module.css"
import React from "react";
import {LogoutOutlined} from "@ant-design/icons";


type GameHeaderProps = {
    title: string,
    currentMove?: number,
    totalMoves?: number,
    isLobby: boolean
}


const GameHeader: React.FC<GameHeaderProps> = ({title, currentMove, totalMoves, isLobby}) => {
    return (
        <Layout.Header className={styles.header}>
            <div className={styles.title}>
                {title}
            </div>
            <div className={styles.info}>
                {isLobby ? "Laukiama žaidėjų..." : `${currentMove}/${totalMoves} Ėjimas`}
            </div>
            <div className={styles.leave}>
                <Button href="/" type="text" style={{color: "white"}} size="large" icon={<LogoutOutlined />}>
                    Išeiti
                </Button>
            </div>
        </Layout.Header>
    )
}

export default GameHeader;