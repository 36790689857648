import styles from './Home.module.css'
import {Layout} from 'antd';
import GameHeader from "../components/GameHeader";
import PlayersList from "../components/PlayersList";
import Title from "antd/lib/typography/Title";
import QuestionLayout from "../components/QuestionLayout";
import {useFirestore, useFirestoreCollectionData, useFirestoreDocData, useUser} from "reactfire";
import {ParamsDoc, PlayerDoc} from "../firebase/firestoreTypes";
import {useEffect, useState} from "react";
import AdminGameLayout from "../components/AdminGameLayout";
import WaitingForPlayers from "../components/WaitingForPlayers";


const currentPlayer = "Partija2"

const players = [
    {
        partyName: "Partija1",
        moveFinished: true,
        totalScore: 0.5
    },
    {
        partyName: "Partija2",
        moveFinished: false,
        totalScore: 0.3
    },
    {
        partyName: "Partija3",
        moveFinished: false,
        totalScore: 0.15
    },
    {
        partyName: "Partija4",
        moveFinished: true,
        totalScore: 0.05
    },

]

export default function Game({code}: {code: string}) {
    const [currentPlayer, setCurrentPlayer] = useState<PlayerDoc>();
    const {Content, Footer, Sider} = Layout
    const gameCollection = useFirestore().collection(code)
    const playerDocs = useFirestoreCollectionData<PlayerDoc>(
        gameCollection.where("isPlayer", "==", true)
    );
    const gameParams = useFirestoreDocData<ParamsDoc>(gameCollection.doc("params"));
    const user = useUser();
    const isAdmin = user.data?.uid === gameParams.data?.createdBy;

    useEffect(() => {
        // Set current player state when it changes
        const player = playerDocs.data?.find(value => value.userId === user.data?.uid);
        setCurrentPlayer(player);
    }, [playerDocs, user]);

    // TODO: Extract common layout
    return (
        <Layout className={styles.layout}>
            <GameHeader isLobby={false}
                        title={gameParams.data?.name}
                        currentMove={gameParams.data?.move}
                        totalMoves={gameParams.data?.totalMoves}/>
            <Layout>
                <Sider width={300} className={styles.sider}>
                    <Title level={3}>Partijos</Title>
                    <PlayersList players={playerDocs.data ?? []} currentPlayer={user.data?.uid}/>
                </Sider>
                <Layout>
                    <Content style={{padding: '50px 50px 0 25px', height: "100%"}}>
                        <div className={styles.content} style={{height: "100%"}}>
                            {
                                isAdmin ?
                                    <AdminGameLayout players={playerDocs.data ?? []} code={code}/>
                                    :
                                    (currentPlayer?.moved ?
                                        <WaitingForPlayers players={playerDocs.data ?? []}/> :
                                        <QuestionLayout code={code}
                                                    currentPlayer={currentPlayer}
                                                    currentMove={gameParams.data?.move}/>
                                    )
                            }
                        </div>
                    </Content>
                </Layout>
            </Layout>
            <Footer style={{textAlign: 'center'}}>©{2021} Artificia</Footer>
        </Layout>
    )
}
