import {Slider} from "antd";
import styles from "./QuestionLayout.module.css"
import {useEffect, useState} from "react";

type MoneySliderProps = {
    name: string,
    label: string,
    max: number,
    value: number,
    onChange?: (name: string, value: number) => any
}

export default function MoneySlider({name, label, max, value, onChange}: MoneySliderProps) {

    // const [value, setValue] = useState(initialValue);

    const onSliderChanged = (value: number) => {
        if(onChange){
            onChange(name, value > 0 ? value : 0);//trying to eliminate negative budget for ads
        }
        // setValue(value);
    }
    // useEffect(() => {
    //     setValue(initialValue);
    // }, [initialValue]);

    return (
        <div className={styles.sliderContainer}>
            <div className={styles.sliderLabel}>{`${label}:`}</div>
            <Slider className={styles.slider} max={max} value={value} step={10} onChange={onSliderChanged}/>
            <div className={styles.sliderIndicator} >{`${value} €`}</div>
        </div>
    )
}