import {PlayerDoc} from "../../firebase/firestoreTypes";
import styles from "./WinnersDisplay.module.css";
import {toPercentage} from "../../firebase/utils";

export default function WinnersDisplay({sortedPlayers}: { sortedPlayers: PlayerDoc[] }) {

    return (
        <div className={styles.wrapper}>
            <div className={`${sortedPlayers[1] ? styles.secondHeight : styles.zeroHeight} ${styles.container}`}>
                <div className={`${styles.second} ${styles.place}`}>
                    <div className={styles.number}>2</div>
                    <div className={styles.partyName}>
                        {sortedPlayers[1]?.partyName}
                    </div>
                    <div className={styles.support}>
                        {toPercentage(sortedPlayers[1]?.score.totals.support ?? 0, 0)}
                    </div>
                    <div className={styles.mandates}>4 mandatai</div>
                </div>
            </div>
            <div className={`${sortedPlayers[0] ? styles.firstHeight : styles.zeroHeight} ${styles.container}`}>
                <div className={`${styles.first} ${styles.place}`}>
                    <div className={styles.number}>1</div>
                    <div className={styles.partyName}>
                        {sortedPlayers[0]?.partyName}
                    </div>
                    <div className={styles.support}>
                        {toPercentage(sortedPlayers[0]?.score.totals.support ?? 0, 0)}
                    </div>
                    <div className={styles.mandates}>5 mandatai</div>
                </div>
            </div>
            <div className={`${sortedPlayers[2] ? styles.thirdHeight : styles.zeroHeight} ${styles.container}`}>
                <div className={`${styles.third} ${styles.place}`}>
                    <div className={styles.number}>3</div>
                    <div className={styles.partyName}>
                        {sortedPlayers[2]?.partyName}
                    </div>
                    <div className={styles.support}>
                        {toPercentage(sortedPlayers[2]?.score.totals.support ?? 0, 0)}
                    </div>
                    <div className={styles.mandates}>3 mandatai</div>
                </div>
            </div>
        </div>
    )
}
