import firebase from "firebase/app";
import "firebase/auth"

export const signInWithGoogle = async (auth: firebase.auth.Auth) => auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());

// Probably use directly
export const signOut = async (auth: firebase.auth.Auth) => auth.signOut()

const acs = {
    url: `${process.env.REACT_APP_DOMAIN}/login-confirm`,
    handleCodeInApp: true

} as firebase.auth.ActionCodeSettings


export const sendSignInLink = async (auth: firebase.auth.Auth, email: string) => auth
    .sendSignInLinkToEmail(email, acs)

export const signInWithEmailLink = async (auth: firebase.auth.Auth, email: string, link: string) => auth
    .signInWithEmailLink(email, link)